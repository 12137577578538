import React, { useState, useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

function hammingDistance(a, b) {
  return a.reduce((acc, bit, i) => acc + (bit !== b[i] ? 1 : 0), 0);
}

function computeHash(imageData) {
  const pixels = imageData.data;
  const grayscale = [];
  for (let i = 0; i < pixels.length; i += 4) {
    const avg = (pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3;
    grayscale.push(avg);
  }
  const avg = grayscale.reduce((a, b) => a + b) / grayscale.length;
  return grayscale.map((v) => (v > avg ? 1 : 0));
}

const extractHashes = async (videoUrl, interval = 1.0) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = videoUrl;
    video.crossOrigin = 'anonymous';
    video.muted = true;
    video.playsInline = true;
    video.preload = 'auto';
    video.width = 32;
    video.height = 32;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    if (!ctx) {
      console.error("❌ Could not get canvas context.");
      resolve([]);
      return;
    }
    canvas.width = 32;
    canvas.height = 32;

    const hashes = [];

    video.onloadedmetadata = () => {
      console.log("✅ Metadata loaded for", videoUrl);
      let currentTime = 0;
      const duration = video.duration;

      const step = () => {
        video.currentTime = currentTime;
      };

      video.onseeked = () => {
        try {
          ctx.drawImage(video, 0, 0, 32, 32);
          const data = ctx.getImageData(0, 0, 32, 32);
          const hash = computeHash(data);
          hashes.push({ time: currentTime, hash });
        } catch (err) {
          console.warn("⚠️ Could not hash frame at", currentTime, err);
        }
        currentTime += interval;
        if (currentTime < duration) {
          step();
        } else {
          console.log("✅ Finished hashing", hashes.length, "frames for", videoUrl);
          resolve(hashes);
        }
      };

      step();
    };

    video.onerror = (e) => {
      console.error("❌ Failed to load video", videoUrl, e);
      resolve([]);
    };
  });
};

function matchFrames(hashesA, hashesB, maxDist = 500) {
  const matches = [];
  for (let i = 0; i < hashesA.length; i++) {
    let best = { dist: Infinity, match: null };
    for (let j = Math.max(0, i - 5); j < Math.min(hashesB.length, i + 5); j++) {
      const dist = hammingDistance(hashesA[i].hash, hashesB[j].hash);
      if (dist < best.dist) {
        best = { dist, match: hashesB[j] };
      }
    }

    if (best.dist < maxDist && best.match) {
      const delta = best.match.time - hashesA[i].time;
      console.log(`🎯 Match: A=${hashesA[i].time}s ↔ B=${best.match.time}s (Δ=${delta.toFixed(2)}s, dist=${best.dist})`);
      matches.push({ time: hashesA[i].time, delta });
    }
  }

  if (!matches.length) return [];

  const t0 = matches[0].time;
  return matches.map((m) => ({ time: m.time - t0, delta: m.delta }));
}

const VideoCompare = () => {
  const [videoA, setVideoA] = useState(null);
  const [videoB, setVideoB] = useState(null);
  const [comparisonData, setComparisonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const graphRef = useRef(null);
  const videoRefA = useRef(null);
  const videoRefB = useRef(null);

  const handleDrop = (e, setter) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('video/')) {
      setter(URL.createObjectURL(file));
    }
  };

  const handleCompare = async () => {
    if (!videoA || !videoB) return;
    setLoading(true);
    console.log("🔍 Starting video comparison...");

    const hashesA = await extractHashes(videoA);
    const hashesB = await extractHashes(videoB);

    if (!hashesA.length || !hashesB.length) {
      alert("Could not extract frames from one or both videos.");
      setLoading(false);
      return;
    }

    const deltas = matchFrames(hashesA, hashesB);
    console.log("✅ Final deltas:", deltas);
    setComparisonData(deltas);
    setLoading(false);
  };

  useEffect(() => {
    if (!comparisonData.length || !graphRef.current) return;

    const ctx = graphRef.current.getContext('2d');
    if (!ctx) return;

    const minY = Math.min(...comparisonData.map((d) => d.delta));
    const maxY = Math.max(...comparisonData.map((d) => d.delta));
    const norm = (val) => (val - minY) / (maxY - minY || 1);

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: comparisonData.map((d) => d.time.toFixed(2)),
        datasets: [
          {
            label: 'Δ Time (B - A)',
            data: comparisonData.map((d) => norm(d.delta)),
            borderColor: 'red',
            fill: false,
            tension: 0.3
          }
        ]
      },
      options: {
        responsive: true,
        onClick: (_, elements) => {
          if (!elements.length) return;
          const index = elements[0].index;
          const { time, delta } = comparisonData[index];
          if (videoRefA.current) videoRefA.current.currentTime = time;
          if (videoRefB.current) videoRefB.current.currentTime = time + delta;
        },
        scales: {
          x: { title: { display: true, text: 'Time (s)' } },
          y: { title: { display: true, text: 'Normalized Δ Time' } }
        }
      }
    });
  }, [comparisonData]);

  return (
    <div style={{ padding: 20 }}>
      <h2>Video Comparison</h2>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div
          onDrop={(e) => handleDrop(e, setVideoA)}
          onDragOver={(e) => e.preventDefault()}
          style={{ border: '2px dashed #aaa', flex: 1, padding: '10px' }}
        >
          {videoA ? <video ref={videoRefA} src={videoA} controls width="100%" /> : "Drop Video A"}
        </div>
        <div
          onDrop={(e) => handleDrop(e, setVideoB)}
          onDragOver={(e) => e.preventDefault()}
          style={{ border: '2px dashed #aaa', flex: 1, padding: '10px' }}
        >
          {videoB ? <video ref={videoRefB} src={videoB} controls width="100%" /> : "Drop Video B"}
        </div>
      </div>
      <button onClick={handleCompare} style={{ marginTop: 20 }} disabled={loading}>
        {loading ? "Comparing..." : "Compare Videos"}
      </button>

      {!comparisonData.length && !loading && (
        <p style={{ color: 'red' }}>❌ No matching frames found. Try adjusting threshold or using more similar videos.</p>
      )}

      <canvas ref={graphRef} style={{ marginTop: 30, width: "100%", height: "500px" }} />
    </div>
  );
};

export default VideoCompare;
