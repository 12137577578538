import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext'; // or wherever you put it
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import MultiDashboard from './components/MultiDashboard';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import BuyCredits from "./components/BuyCredits";
import Checkout from "./components/Checkout";
import Homepage from "./components/Homepage";
import Landing from "./components/Landing";
import TrackSimulation from "./components/TrackSimulation";
import VideoCompare from './components/VideoCompare';
import PrivateRoute from './components/PrivateRoute'; // We'll define below
import AdminPage from "./components/AdminPage";
function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="app-container">
          {/* Header */}
          <Header />

          {/* Main Content */}
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Navigate to="/landing" />} />
              <Route path="/landing" element={<Landing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/tos" element={<TermsOfService />} />
              <Route path="/buy-credits" element={<BuyCredits />} />
              <Route path="/homepage" element={<Homepage />} />
              <Route path="/video-compare" element={<VideoCompare />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/track-simulation" element={<TrackSimulation />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <MultiDashboard />
                  </PrivateRoute>
                }
              />
              <Route path="/multi-dashboard" element={<MultiDashboard />} />
            </Routes>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;