import React, { useState, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Chart from "chart.js/auto";
import FitParser from 'fit-file-parser';
import { calculateDistance } from "../utils/telemetryUtils";

function TrackSimulation() {
  const [profileFiles, setProfileFiles] = useState([]);
  const [targetTrack, setTargetTrack] = useState(null);
  const [chart, setChart] = useState(null);
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [simulatedTime, setSimulatedTime] = useState(null);

  const handleProfileDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setProfileFiles((prev) => [...prev, ...files]);
  };

  const handleTargetDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setTargetTrack(file);
  };

  const parseGPX = async (file) => {
    const text = await file.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, "application/xml");
    const trkpts = [...xmlDoc.getElementsByTagName("trkpt")];
    return trkpts.map((pt) => ({
      lat: parseFloat(pt.getAttribute("lat")),
      lon: parseFloat(pt.getAttribute("lon")),
      ele: parseFloat(pt.getElementsByTagName("ele")[0]?.textContent || 0),
      time: Date.parse(pt.getElementsByTagName("time")[0]?.textContent || "") / 1000
    }));
  };

  function classifySurface(tags) {
    const surface = tags.surface || "";
    const highway = tags.highway || "";

    if (["asphalt", "concrete", "paved"].includes(surface)) return "road";
    if (["gravel", "dirt", "ground", "unpaved"].includes(surface)) return "trail";

    if (["path", "track", "cycleway"].includes(highway)) return "trail";
    if (["residential", "primary", "secondary", "tertiary"].includes(highway)) return "road";

    return "unknown";
  }

  async function fetchTerrainTypesBatch(points, batchSize = 50) {
    const terrainCache = new Map();

    for (let i = 0; i < points.length; i += batchSize) {
      const batch = points.slice(i, i + batchSize);
      const queries = batch.map((pt) => `node(around:10,${pt.lat},${pt.lon}); out tags;`).join("");
      const overpassQuery = `[out:json][timeout:25];(${queries});`;
      const url = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`;

      try {
        const res = await fetch(url);
        const json = await res.json();
        for (let j = 0; j < batch.length; j++) {
          const key = `${batch[j].lat.toFixed(5)},${batch[j].lon.toFixed(5)}`;
          const tags = json.elements[j]?.tags || {};
          terrainCache.set(key, classifySurface(tags));
        }
      } catch (err) {
        console.error("Overpass batch lookup failed:", err);
        for (const pt of batch) {
          const key = `${pt.lat.toFixed(5)},${pt.lon.toFixed(5)}`;
          terrainCache.set(key, "unknown");
        }
      }
    }

    return terrainCache;
  }

  const buildProfile = async () => {
    if (profileFiles.length === 0) return;
    const profile = {};
    const getBin = (obj, ...keys) => keys.reduce((o, k) => (o[k] = o[k] || {}), obj);
    const allPoints = [];
    const fileData = new Map();

    for (const file of profileFiles) {
      const ext = file.name.split(".").pop().toLowerCase();
      let points = [];

      if (ext === "gpx") {
        points = await parseGPX(file);
      } else if (ext === "fit") {
        points = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const fitParser = new FitParser({ force: true });
            fitParser.parse(e.target.result, (err, data) => {
              if (err || !data.records) return resolve([]);
              const records = data.records.map((r) => ({
                time: new Date(r.timestamp).getTime() / 1000,
                lat: r.position_lat,
                lon: r.position_long,
                elevation: r.altitude,
                speed: r.speed ?? 0,
                heartRate: r.heart_rate,
                power: r.power
              }));
              resolve(records.filter((p) => p.lat && p.lon));
            });
          };
          reader.readAsArrayBuffer(file);
        });
      }

      if (points.length < 2) continue;
      fileData.set(file.name, points);
      allPoints.push(...points);
    }

    const terrainCache = await fetchTerrainTypesBatch(allPoints);
    const allSpeedsByBin = {};

    for (const [fileName, points] of fileData.entries()) {
      const startTime = points[0].time;
      points.forEach((p) => (p.relTime = p.time - startTime));

      for (let i = 1; i < points.length; i++) {
        const a = points[i - 1];
        const b = points[i];
        const dt = b.relTime - a.relTime;
        if (dt <= 0) continue;

        const dx = calculateDistance(a.lat, a.lon, b.lat, b.lon);
        const speed = dx / dt;
        const de = b.elevation - a.elevation;
        const grade = de / dx;
        const slope = grade * 100;

        const fatigue = b.relTime < 7200 ? "fresh" : b.relTime < 21600 ? "moderate" : "fatigued";
        const slopeBin = slope > 6 ? "steep" : slope > 3 ? "moderate" : slope < -3 ? "descent" : "flat";
        
        const safe = (val) => (typeof val === 'string' && val.length > 0 ? val : "unknown");

        const terrainKey = safe(terrainCache.get(`${b.lat.toFixed(5)},${b.lon.toFixed(5)}`) || "unknown");
        const slopeKey = safe(slopeBin);
        const fatigueKey = safe(fatigue);

        const binKey = `${terrainKey}-${slopeKey}-${fatigueKey}`;
        allSpeedsByBin[binKey] = allSpeedsByBin[binKey] || [];
        allSpeedsByBin[binKey].push(speed * 3.6);

        const bin = getBin(profile, terrainKey, slopeKey, fatigueKey);
        if (!bin.totalDistance) {
          Object.assign(bin, {
            totalDistance: 0,
            totalTime: 0,
            sumHR: 0,
            sumPower: 0,
            hrCount: 0,
            powerCount: 0,
            count: 0
          });
        }

        bin.totalDistance += dx;
        bin.totalTime += dt;
        if (b.heartRate) {
          bin.sumHR += b.heartRate;
          bin.hrCount++;
        }
        if (b.power) {
          bin.sumPower += b.power;
          bin.powerCount++;
        }
        bin.count++;
      }
    }

    const averagedProfile = {};
    for (const terrain in profile) {
      averagedProfile[terrain] = {};
      for (const slope in profile[terrain]) {
        averagedProfile[terrain][slope] = {};
        for (const fatigue in profile[terrain][slope]) {
          const bin = profile[terrain][slope][fatigue];

          const terrainKey = terrain;
          const slopeKey = slope;
          const fatigueKey = fatigue;
          const binKey = `${terrainKey}-${slopeKey}-${fatigueKey}`;
          const speeds = allSpeedsByBin[binKey] || [];
          const mean = speeds.reduce((a, b) => a + b, 0) / speeds.length;
          const stddev = Math.sqrt(speeds.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / speeds.length);
          const filteredSpeeds = speeds.filter(s => s >= mean - 2 * stddev && s <= mean + 2 * stddev);
          const avgSpeed = filteredSpeeds.reduce((a, b) => a + b, 0) / filteredSpeeds.length;

          averagedProfile[terrain][slope][fatigue] = {
            speed: avgSpeed,
            avgHR: bin.hrCount > 0 ? bin.sumHR / bin.hrCount : null,
            avgPower: bin.powerCount > 0 ? bin.sumPower / bin.powerCount : null,
            count: bin.count
          };
        }
      }
    }

    console.log("✅ Rider Performance Profile (filtered):", averagedProfile);
    alert("✅ Profile built! Check console for output.");
  };

  const simulatePerformance = async () => {
    if (!targetTrack) return;
    const trackPoints = await parseGPX(targetTrack);

    // Dummy simulation: speed = 15km/h constant
    const simulated = trackPoints.map((pt, i) => ({
      ...pt,
      speed: 15 + 5 * Math.sin(i / 20),
      timeIndex: i
    }));

    displayMap(simulated);
    displayChart(simulated);

    setSimulatedTime(trackPoints.length / (15 / 3.6)); // rough estimate
  };

  const displayMap = (points) => {
    if (!mapRef.current || !points || points.length === 0) return;
  
    // Clear existing map if already exists
    if (mapRef.current._leaflet_id !== undefined) {
      mapRef.current._leaflet_id = null;
    }
  
    // Always create a new map (prevents map being null or reused incorrectly)
    const newMap = L.map(mapRef.current).setView([points[0].lat, points[0].lon], 12);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(newMap);
  
    const latlngs = points.map((pt) => [pt.lat, pt.lon]);
    const polyline = L.polyline(latlngs, { color: "blue" }).addTo(newMap);
    newMap.fitBounds(polyline.getBounds());
  
    const marker = L.circleMarker(latlngs[0], {
      radius: 6,
      color: "red",
      fillOpacity: 0.8
    }).addTo(newMap);
  
    setMap(newMap); // Store in state after all is safe
    markerRef.current = marker;
  };

  const displayChart = (points) => {
    const ctx = document.getElementById("track-sim-chart").getContext("2d");
    if (chart) chart.destroy();

    const newChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: points.map((pt) => pt.timeIndex),
        datasets: [
          {
            label: "Simulated Speed (km/h)",
            data: points.map((pt) => pt.speed),
            borderWidth: 2,
            fill: false,
            borderColor: "blue",
            pointRadius: 1
          }
        ]
      },
      options: {
        onClick: (e, elements) => {
          if (elements.length > 0) {
            const index = elements[0].index;
            const { lat, lon } = points[index];
            if (markerRef.current) markerRef.current.setLatLng([lat, lon]);
          }
        },
        responsive: true,
        scales: {
          x: { title: { display: true, text: "Time Index" } },
          y: { title: { display: true, text: "Speed (km/h)" } }
        }
      }
    });

    setChart(newChart);
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>🏁 Track Simulation</h2>

      <div
        onDrop={handleProfileDrop}
        onDragOver={(e) => e.preventDefault()}
        style={{ border: "2px dashed #999", padding: 20, marginBottom: 20 }}
      >
        📂 Drop past GPX/FIT files here to build your profile
        <br />
        <strong>{profileFiles.length} file(s) loaded</strong>
        <br />
        <button onClick={buildProfile} style={{ marginTop: 10 }}>
          🚀 Build Profile
        </button>
      </div>

      <div
        onDrop={handleTargetDrop}
        onDragOver={(e) => e.preventDefault()}
        style={{ border: "2px dashed #666", padding: 20, marginBottom: 20 }}
      >
        🎯 Drop the GPX track to simulate
        <br />
        {targetTrack && <strong>Loaded: {targetTrack.name}</strong>}
        <br />
        <button onClick={simulatePerformance} style={{ marginTop: 10 }}>
          🧠 Simulate Performance
        </button>
      </div>

      {simulatedTime && (
        <p>⏱ Estimated Time: <strong>{(simulatedTime / 3600).toFixed(2)} hours</strong></p>
      )}

      <div
        ref={mapRef}
        style={{ height: 300, marginBottom: 20, border: "1px solid #aaa" }}
      ></div>

      <canvas id="track-sim-chart" height="100"></canvas>
    </div>
  );
}

export default TrackSimulation;