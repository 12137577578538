// Homepage.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where, doc, onSnapshot, deleteDoc, updateDoc,setDoc, arrayRemove,arrayUnion, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import "../App.css";
import { useAuth } from "../contexts/AuthContext";
import { FaApple, FaWindows, FaClock, FaTimes,FaVideo, FaCamera, FaQuestionCircle, FaRegSmile, FaTrash, FaShareAlt, FaUnlink, FaArrowRight } from "react-icons/fa";
import FAQ from "../components/FAQ";
// Helper: Extract YouTube Video ID from URL (assumes standard URL format)
const extractYouTubeVideoID = (url) => {
  const regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/;
  const match = url.match(regExp);
  return match ? match[1] : "";
};

const Homepage = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const [runs, setRuns] = useState([]);
  const [filteredRuns, setFilteredRuns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("date"); // "name" or "date"
  const [sortOrder, setSortOrder] = useState("desc"); // "asc" or "desc"
  const [faqOpenIndex, setFaqOpenIndex] = useState(null);
  const [userData, setUserData] = useState(null);
  const [sharedRuns, setSharedRuns] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
const [selectedRunToShare, setSelectedRunToShare] = useState(null);
const [shareUsername, setShareUsername] = useState("");
const [demoModalMainOpen, setDemoModalMainOpen] = useState(false);
const [demoModalTimerOpen, setDemoModalTimerOpen] = useState(false);
  
  // New state for user events (for Other Tools)
  const [events, setEvents] = useState([]);

  // Fetch user data (for accurate credits)
  useEffect(() => {
    if (!user) {
      setUserData(null);
      return;
    }
    const docRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        setUserData(snapshot.data());
      }
    });
    return () => unsubscribe();
  }, [user]);

  // Fetch runs for logged-in user
  useEffect(() => {
    const fetchRuns = async () => {
      try {
        const runsRef = collection(db, "runs");
        const q = query(
          runsRef,
          where("userId", "==", user.uid),
          orderBy("timestamp", "desc")
        );
        setLoading(true);
        const querySnapshot = await getDocs(q);
        const runsList = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          runsList.push({
            id: docSnap.id,
            ...data,
            date: data.timestamp?.seconds
              ? new Date(data.timestamp.seconds * 1000).toLocaleString()
              : "Unknown Date",
          });
        });
        setRuns(runsList);
        setFilteredRuns(runsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching runs", error);
      }
    };
    if (user) fetchRuns();
  }, [user]);

  useEffect(() => {
    const fetchSharedRuns = async () => {
      try {
        const runsRef = collection(db, "runs");
        const q = query(runsRef, where("sharedWith", "array-contains", user.uid));
        const querySnapshot = await getDocs(q);
        const sharedList = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          sharedList.push({
            id: docSnap.id,
            ...data,
            date: data.timestamp?.seconds
              ? new Date(data.timestamp.seconds * 1000).toLocaleString()
              : "Unknown Date",
          });
        });
        setSharedRuns(sharedList);
      } catch (error) {
        console.error("Error fetching shared runs", error);
      }
    };
    if (user) fetchSharedRuns();
  }, [user]);
  // New: Fetch events for logged-in user (Other Tools section)
  useEffect(() => {
    if (!user) return;
    const eventsRef = collection(db, "events");
    const q = query(
      collection(db, "events"),
      where("admins", "array-contains-any", [user.uid, user.email])
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const eventsList = snapshot.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data()
      }));
      setEvents(eventsList);
    });
    return () => unsubscribe();
  }, [user]);


  const generateDiceBearAvatar = (username) => {
    return `https://api.dicebear.com/7.x/identicon/svg?seed=${encodeURIComponent(username)}`;
  };

  const handleDeleteRun = async (runId) => {
    try {
      await deleteDoc(doc(db, "runs", runId));
      // Remove the run from the local state arrays
      setRuns(prevRuns => prevRuns.filter(run => run.id !== runId));
      setFilteredRuns(prevRuns => prevRuns.filter(run => run.id !== runId));
    } catch (error) {
      console.error("Error deleting run:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout error", error);
    }
  };

  // Handle search input for runs
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = runs.filter((run) =>
      run.runName.toLowerCase().includes(value)
    );
    setFilteredRuns(filtered);
  };

  // Sort runs when header is clicked
  const sortRuns = (column) => {
    let newOrder = sortOrder;
    if (sortType === column) {
      newOrder = sortOrder === "asc" ? "desc" : "asc";
      setSortOrder(newOrder);
    } else {
      setSortType(column);
      newOrder = "asc";
      setSortOrder("asc");
    }
    const sorted = [...filteredRuns].sort((a, b) => {
      if (column === "name") {
        return newOrder === "asc"
          ? a.runName.localeCompare(b.runName)
          : b.runName.localeCompare(a.runName);
      } else if (column === "date") {
        const aTime = a.timestamp ? a.timestamp.seconds : 0;
        const bTime = b.timestamp ? b.timestamp.seconds : 0;
        return newOrder === "asc" ? aTime - bTime : bTime - aTime;
      }
      return 0;
    });
    setFilteredRuns(sorted);
  };

  const openShareModal = (run) => {
    setSelectedRunToShare(run);
    setShareModalOpen(true);
  };

  const handleShareRun = async () => {
    console.log("Current user UID:", user.uid);
    console.log("Run ownerId:", selectedRunToShare.ownerId);
  
    const sharedUser = await getUserByUsernameOrEmail(shareUsername);
    if (!sharedUser) {
      alert("User not found!");
      return;
    }
    console.log("Shared user's UID:", sharedUser.uid);
  
    try {
      const runDocRef = doc(db, "runs", selectedRunToShare.id);
      const updatePayload = {
        sharedWith: arrayUnion(sharedUser.uid)
      };
      console.log("Update payload:", updatePayload);
      await updateDoc(runDocRef, updatePayload);
      alert("Run shared successfully!");
      setShareModalOpen(false);
      setShareUsername("");
    } catch (error) {
      console.error("Error sharing run", error);
      alert("Error sharing run!");
    }
  };

  const getUserByUsernameOrEmail = async (identifier) => {
    const usersRef = collection(db, "users");
    
    // Try email first
    let q = query(usersRef, where("email", "==", identifier));
    let querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const docSnap = querySnapshot.docs[0];
      return { uid: docSnap.id, ...docSnap.data() };
    }
    
    // Then try username
    q = query(usersRef, where("username", "==", identifier));
    querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const docSnap = querySnapshot.docs[0];
      return { uid: docSnap.id, ...docSnap.data() };
    }
    
    return null;
  };

  const unlinkRun = async (runId) => {
    try {
      const runDocRef = doc(db, "runs", runId);
      // Remove the current user's uid from sharedWith using arrayRemove
      await updateDoc(runDocRef, {
        sharedWith: arrayRemove(user.uid)
      });
      alert("Run unlinked successfully!");
      // Optionally refresh the shared runs list
    } catch (error) {
      console.error("Error unlinking run", error);
      alert("Error unlinking run!");
    }
  };

  // Load a run: navigate to multi-dashboard with runId as a query parameter
  const loadRun = (selectedRun) => {
    navigate(`/multi-dashboard?runId=${selectedRun.id}`);
  };

  // Toggle FAQ answer display
  const toggleFaq = (index) => {
    setFaqOpenIndex((prev) => (prev === index ? null : index));
  };
  const nameToDisplay = userData?.username || user?.displayName || user?.email || "Guest";
  // For the user icon, use the user's initials.
  const userInitials = user?.email
    ? user.email.match(/\b\w/g).join("").toUpperCase()
    : "U";

  // FAQ items (10 questions)
  const faqItems = [
    {
      question: "⚙️ What is this tool?",
      answer:
        "This tool synchronizes video and differnt kind of data (braking, gps, speed, etc.).  You can overlay your metrics directly on your videos so you can easily analyze your performance.",
    },
    {
      question: "🤔 What file formats are supported?",
      answer:
        "Our tool supports multiple data acquisition formats. You can use CSV files exported from our Brake Ace Desktop application—which include braking data along with GPS, speed, and other metrics—as well as CSV files exported from the BYB data acquisition system, which provide additional details such as suspension (fork and shock) positions, acceleration (X, Y, Z), and gyro data. For video, common formats like MP4 and AVI are accepted, allowing you to analyze performance from various sources seamlessly."
    },
    {
      question: "📹 How can I use data from my GoPro camera?",
      answer:
        "You can acquire data from your GoPro camera in two ways: by using our online extraction tool, or by using our desktop tool (download links provided below). The online tool extracts data directly from your uploaded file, while the desktop tool processes the file locally—ideal for large files and when you want faster performance without uploading large videos."
    },
    {
      question: "🛠 How can I use data from Brake Ace sensors?",
      answer:
        "Brake Ace sensor data is supported through CSV files exported from our Brake Ace Desktop application. These files contain essential braking data that can be overlaid on your videos, giving you a detailed analysis of your braking performance and overall riding dynamics."
    },
    {
      question: "🚀 How can I use data from the BYB data acquisition system?",
      answer:
        "The BYB data acquisition system offers more than traditional telemetry by including extra data points such as suspension information. Its CSV exports include fork and shock positions, along with acceleration and gyro data. This additional information allows you to analyze the behavior of your suspension system at various points along the track, enabling a more in-depth performance analysis."
    },
    {
      question: "🔄 How do I sync data with my video?",
      answer:
        "Use our 'Sync Data' tool to align the video timestamp with the start of your data acquisition, ensuring that your performance data and video playback are perfectly synchronized for accurate analysis."
    },
    {
      question: "💳 How are credits used?",
      answer:
        "Each analysis or video processing task consumes a specific number of credits. Your available credits update in real time so you always know your balance. Credits help cover our server and processing costs."
    },
    {
      question: "🛒 How do I purchase more credits?",
      answer:
        "Click the 'Purchase Credits' button in the sidebar to buy additional credits. The process is secure, handled by Stripe, and your credits are updated immediately upon purchase."
    },
    {
      question: "📁 How do I save and load runs?",
      answer:
        "Simply click the save button to store your run. You have the option to save your data locally or in the cloud. Use the search and sort features in the runs table to quickly find and select a specific run for detailed analysis."
    },
    {
      question: "📝 Can I view detailed metrics for each run?",
      answer:
        "Absolutely! Once you load a run, your dashboard displays detailed metrics such as speed, braking force, suspension positions, split comparisons, and more—providing a full breakdown of your performance."
    },
  ];

  return (
    <div className="homepage-container">
      {/* Sidebar – 30% width */}
      <div className="homepage-sidebar">
        <div className="user-panel">
          <div className="user-icon">
          <img src={generateDiceBearAvatar(user.email)} className="user-avatar" alt="User Avatar" />
          {/* {userInitials} */}
          </div>
          <div className="user-info">
            <h3>{nameToDisplay || user.email}</h3>
            <p>{user.email}</p>
            <p>
              Credits: <strong>{userData?.credits ?? 0}</strong> ⚡
            </p>
          </div>
          <button className="primary-btn" 
          style={{ marginTop: "10px",
            width: "100%",}}
          onClick={() => navigate("/buy-credits")}>
            Purchase Credits
          </button>
          <button className="secondary-btn" onClick={handleLogout}
          style={{ marginTop: "10px",
          width: "100%",}}>
            Logout
          </button>
        </div>
      </div>

      {/* Main content – 70% width */}
  
      <div className="homepage-main">
        <div className="homepage-action">
        <h1>📹 📈 Video/Data Analysis tool</h1>
        <p>This tool overlays sensor data (braking, gps, spped. power, hr, etc.) onto your video. It lets you compare GPS, Brake Ace telemetry, and more to easily analyze performance.</p>
          
        <div style={{ marginTop: "10px", textAlign: "left" }}>
  <button 
    style={{ 
      background: "none", 
      border: "none", 
      color: "#007bff", 
      cursor: "pointer", 
      fontSize: "16px" 
    }}
    onClick={() => setDemoModalMainOpen(true)}
  >
    <FaVideo style={{ marginRight: "5px" }} /> view demo video
  </button><br/><br/>
</div>
          <button className="big-action-btn" onClick={() => navigate("/multi-dashboard")}>
            🚀 Start Analyzing Runs
          </button>
        </div>

        <div className="homepage-runs">
          <h2>My Runs</h2>
          <input
            type="text"
            placeholder="🔍 Search runs..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          {loading ? (
            <div style={{ padding: "20px", textAlign: "center" }}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#13A1A8"
                style={{ margin: "10px auto", display: "block" }}
              >
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(2 2)" strokeWidth="3">
                    <circle strokeOpacity=".25" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </g>
                </g>
              </svg>
              <span style={{ fontSize: "16px", color: "#333" }}>Loading runs...</span>
            </div>
          ) : filteredRuns.length > 0 ? (
            <table className="run-table">
              <thead>
                <tr>
                  <th onClick={() => sortRuns("name")}>
                    Run Name {sortType === "name" && (sortOrder === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortRuns("date")}>
                    Date {sortType === "date" && (sortOrder === "asc" ? "▲" : "▼")}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredRuns.map((run) => (
                  <tr key={run.id}>
                  <td>
  <div className="run-name-cell">
    {run.youtubeUrl ? (
      <img
        src={`https://img.youtube.com/vi/${extractYouTubeVideoID(run.youtubeUrl)}/hqdefault.jpg`}
        alt="Run Thumbnail"
        className="run-thumb"
      />
    ) : (
      <div className="placeholder-thumbnail">
        <FaCamera size={40} color="#ccc" />
      </div>
    )}
    <span className="run-title">{run.runName || "Unnamed Run"}</span>
  </div>
</td>
<td>
  <span className="run-date">{run.date}</span>
</td>
                    
                    <td>
                      <button className="select-btn" onClick={() => handleDeleteRun(run.id)}>
                      <FaTrash size={10} title="Delete" style={{marginRight: "5px"}} />Delete
                      </button>
                    </td>
                    <td>
                    <button className="delete-btn" onClick={() => openShareModal(run)}>
                    <FaShareAlt size={10} title="Share" style={{marginRight: "5px"}}/>Share
                    </button>
                  </td>
                    <td>
                      <button className="delete-btn" onClick={() => loadRun(run)}>
                      <FaArrowRight size={10} title="Select" style={{marginRight: "5px"}}/>Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No runs saved yet</p>
          )}
        </div>
        <h2>Shared Runs</h2>
{sharedRuns.length > 0 ? (
  <table className="run-table">
    <thead>
      <tr>
        <th>Run Name</th>
        <th>Date</th>
        <th>Unlink</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {sharedRuns.map((run) => (
        <tr key={run.id}>
          <td>
            <div className="run-name-cell">
              {run.youtubeUrl ? (
                <img
                  src={`https://img.youtube.com/vi/${extractYouTubeVideoID(run.youtubeUrl)}/hqdefault.jpg`}
                  alt="Run Thumbnail"
                  className="run-thumb"
                />
              ) : (
                <div className="placeholder-thumbnail">
                  <FaCamera size={40} color="#ccc" />
                </div>
              )}
              <span>{run.runName || "Unnamed Run"}</span>
            </div>
          </td>
          <td>{run.date}</td>
          <td>
            <button className="select-btn" onClick={() => unlinkRun(run.id)}>
            <FaUnlink size={10} title="Unlink" /> Unlink
            </button>
          </td>
          <td>
            <button className="delete-btn" onClick={() => loadRun(run)}>
            <FaArrowRight size={10} title="Select" /> Select
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p>No shared runs yet</p>
)}

{shareModalOpen && (
  <div className="modal-overlay">
    <div className="modal">
      <h3>Share Run</h3>
      <p>
        Enter a username or email to share run "{selectedRunToShare?.runName}":
      </p>
      <input
        type="text"
        value={shareUsername}
        onChange={(e) => setShareUsername(e.target.value)}
        placeholder="Username or email"
      />
      <div style={{ marginTop: "10px" }}>
        <button className="primary-btn" onClick={handleShareRun}>
          Share
        </button>
        <button className="secondary-btn" onClick={() => setShareModalOpen(false)}>
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

        {/* FAQ Section */}
        <br/><br/><hr/>
        <div className="homepage-faq">
          <h2>FAQ</h2>
          <FAQ />
        </div>

{/* New: Other Tools Section */}
<br/><hr/><br/>
{/* Download Desktop Tools Section */}
<div className="homepage-downloads">
  <h2>Download Desktop Tools</h2>
  <p className="homepage-paragrah">
    Our online tool can extract data from your GoPro file, but transferring large files over the internet can be time consuming and demands a strong connection.
    That’s why we provide a desktop version that extracts your GoPro data locally—faster, more reliable, and completely offline.
  </p>
  <div className="download-links" style={{ marginTop: "15px" }}>
    <a 
      href="download/macos_process_gopro_desktop.zip" 
      className="download-link" 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ 
        marginRight: "20px", 
        textDecoration: "none", 
        color: "#000", 
        fontSize: "20px", 
        display: "inline-block",
        padding: "10px 15px",
        border: "2px solid #000",
        borderRadius: "5px"
      }}
    >
      <FaApple style={{ marginRight: "10px", fontSize: "24px" }} /> Download for macOS
    </a>
    <a 
      href="download/windows_process_gopro_desktop.zip" 
      className="download-link" 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ 
        textDecoration: "none", 
        color: "#000", 
        fontSize: "20px", 
        display: "inline-block",
        padding: "10px 15px",
        border: "2px solid #000",
        borderRadius: "5px"
      }}
    >
      <FaWindows style={{ marginRight: "10px", fontSize: "24px" }} /> Download for Windows
    </a>
  </div>
  <br/><hr/>
  <p style={{ marginTop: "20px", fontSize: "16px" }} className="homepage-paragrah">
    Also, check out{" "}
    <a 
      href="https://chronocult.com" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
    >
      <FaClock style={{ marginRight: "10px", fontSize: "28px" }}/> Chronocult
    </a>. <br/><br/>Chronocult is a powerful and easy-to-use timing system that lets you effortlessly track and manage your performance data.
    With its intuitive interface and comprehensive features, Chronocult helps athletes, coaches, and enthusiasts accurately record split times,
    lap durations, and overall performance metrics. Whether you’re timing a race, a training session, or a cycling event, Chronocult
    delivers reliable and detailed timing information that you can easily export and analyze. Explore Chronocult to experience a seamless
    blend of simplicity and functionality, designed to elevate your performance tracking to the next level.
  </p>
</div>
      </div>

      {demoModalMainOpen && (
  <div className="modal-overlay" onClick={() => setDemoModalMainOpen(false)}>
    <div className="modal" onClick={(e) => e.stopPropagation()} style={{ position: 'relative', width: '80%' }}>
      {/* New Close Button (Cross Icon) in the Top Right */}
      <button className="modal-close-btn" onClick={() => setDemoModalMainOpen(false)}>
        <FaTimes />
      </button>
      <h3>Demo Video – Video/Data Analysis Tool</h3>
      <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
        <iframe 
          title="Demo Video" 
          src="https://www.youtube.com/embed/VVgDmG3AIX8" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen 
          style={{ position: "absolute", top:0, left:0, width:"100%", height:"100%" }}
        />
      </div>
    </div>
  </div>
)}

    </div>



  );
};

export default Homepage;